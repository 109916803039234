




































































































import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { ADORI_INSIGHTS_BASE_URL } from '@/constants'
import get from 'lodash.get'

import icons_campaign_icon_active from '@/assets/icons/campaign_icon_active.svg'
import icons_campaign_icon from '@/assets/icons/campaign_icon.svg'

@Component
export default class TheSideNavigation extends Vue {
  @Getter email!: string
  @Getter networkType!: string
  @Getter currentNetwork!: any
  @Getter isYoutube!: any
  @Getter isStudio!: any
  @Getter isYoutubeNew!: any
  @Getter isVideo!: any
  @Getter isYoutubeOld!: any
  @Getter selectedProduct!: string

  get isShowAdmin() {
    return get(this.currentNetwork, 'feeds', false).findIndex((feed: any) => feed?.role?.name === 'Show Admin') >= 0
  }

  get isNetworkOwner() {
    return this.currentNetwork?.role?.name === 'Network Owner'
  }

  get userEmail() {
    return this.email
  }
  isPath(path: string) {
    return this.$router.currentRoute.fullPath === path
  }
  isPathRegex(regex: RegExp) {
    return regex.test(this.$router.currentRoute.fullPath)
  }
  handleInsights() {
    window.open(`${ADORI_INSIGHTS_BASE_URL}`, '_blank')
  }
  get campaign_icon_active() {
    return icons_campaign_icon_active
  }
  get campaign_icon() {
    return icons_campaign_icon
  }
  get disableAccordingToUser() {
    if (this.email && this.email === 'demo+apple@adorilabs.com') {
      return true
    } else {
      return false
    }
  }
}
